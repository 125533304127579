import styled from '@emotion/styled';
import { THEME } from '../../../common/consts/theme';
import { defaultContainerSize, mq } from '../../../styles/mixins';
import { css } from '@emotion/react';
import { Header1, ParagraphS } from '../../core/Typography/Typography';
import LinkButtonWithGradientHover from 'styledComponents/LinkButtonWithGradientHover';

export const Section = styled.section`
  ${defaultContainerSize};

  display: flex;
  flex-wrap: wrap;
  z-index: 0;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 3rem;
  row-gap: 1.5rem;

  ${mq.medium(css`
    padding-top: 5rem;
    padding-bottom: 4rem;
    row-gap: 2rem;
  `)}
`;

export const Title = styled.h2`
  ${Header1};
  color: ${({ theme }) => theme.colors.core_black};
  margin: 0rem 0rem 1rem 0rem;
  ${mq.mSmall(css`
    padding-right: 2rem;
    max-width: 100%;
  `)};

  ${mq.smallIp(css`
    padding-right: 2rem;
    max-width: 28rem;
  `)};

  ${mq.medium(css`
    padding-right: 0rem;
    max-width: 38rem;
  `)};
  ${mq.large(css`
    max-width: 40rem;
  `)};

  ${mq.xLarge(css`
    margin: 0rem 0rem 1.375rem 0rem;
    max-width: 50rem;
  `)};
`;

export const Description = styled.span`
  ${ParagraphS};
  margin: 0;
  color: ${THEME.colors.core_black};
  flex: 3 1;

  display: inline;

  ${mq.mobileMax(css`
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
  `)}

  ${mq.medium(css`
    display: inline-block;
  `)};
`;

export const DescriptionWrapper = styled.div`
  float: left;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LearnMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  padding-top: 0rem;

  ${mq.xLarge(css`
    margin-top: 0;
    flex: 1;
    justify-content: flex-start;
  `)}
`;

export const StyledLinkButtonWithGradientHover = styled(
  LinkButtonWithGradientHover
)`
  transition: background-color 3000ms ease-in-out;
  padding: 0.5rem 0rem;
  ${mq.medium(css`
    padding: 0.75rem 0;
  `)};
  &:hover {
    background: linear-gradient(
      67.33deg,
      ${THEME.colors.primary} -19.99%,
      ${THEME.colors.accent} 98.54%
    );
  }
  svg {
    margin-left: 16px;
    path {
      transition: stroke 200ms ease-in-out;
      stroke: ${THEME.colors.core_white};
    }
  }
`;
