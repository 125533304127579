import { RichText } from 'prismic-reactjs';

import { IWhoWeAreProps } from './interfaces';
import { Routes } from '../../../models/enums/routes';
import {
  ContentWrapper,
  Description,
  DescriptionWrapper,
  LearnMoreWrapper,
  Section,
  StyledLinkButtonWithGradientHover,
  Title,
} from './whoWeAre.styles';

export const WhoWeAre = ({
  title,
  description,
  descriptionSecondary,
  buttonLabel,
}: IWhoWeAreProps): JSX.Element => {
  return (
    <Section>
      <ContentWrapper>
        <Title>{RichText.asText(title)}</Title>

        <DescriptionWrapper>
          <Description>{RichText.asText(description)}</Description>
          <Description>{RichText.asText(descriptionSecondary)}</Description>
        </DescriptionWrapper>
      </ContentWrapper>

      <LearnMoreWrapper>
        <StyledLinkButtonWithGradientHover href={Routes.AboutUs}>
          {RichText.asText(buttonLabel)}
          <svg
            width={'21'}
            height={'12'}
            viewBox={'0 0 21 12'}
            xmlns={'http://www.w3.org/2000/svg'}
          >
            <path
              d={'M1 6H19.5M19.5 6L14.5 1M19.5 6L14.5 11'}
              strokeWidth={'2'}
              strokeLinecap={'round'}
            />
          </svg>
        </StyledLinkButtonWithGradientHover>
      </LearnMoreWrapper>
    </Section>
  );
};
